import { useUserStore } from '~/stores/user';
import { useCommonStore } from '~/stores/common';

const doesHttpOnlyCookieExist = (cookiename: string) => {
  const d = new Date();
  d.setTime(d.getTime() + 1000);
  const expires = 'expires=' + d.toUTCString();

  document.cookie = cookiename + '=new_value;path=/;' + expires;
  return document.cookie.indexOf(cookiename + '=') == -1;
};

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const commonStore = useCommonStore();

  if (process.client) {
    const user = await userStore.check().catch(() => null);
    const cookieExists = doesHttpOnlyCookieExist('sid');

    if (user || (cookieExists && userStore.isLoggedIn)) {
      const redirectUrl =
        to.query.redirect || (await commonStore.getRedirectUrl());

      if (redirectUrl) {
        commonStore.removeRedirectUrl();
      }

      if (
        redirectUrl &&
        redirectUrl !== to.fullPath &&
        redirectUrl !== '/logout'
      ) {
        if (typeof redirectUrl === 'string') {
          return navigateTo({
            path: redirectUrl,
          });
        } else if (Array.isArray(redirectUrl) && redirectUrl[0]) {
          return navigateTo({
            path: redirectUrl[0],
          });
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { redirect, ...query } = to.query;
        return navigateTo({ path: '/', query });
      }
    }
  }
});
